/* eslint-disable-next-line */
export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

export function Container({ className, children }: ContainerProps) {
  return (
    <div className={`container px-5 mx-auto ${className}`}>
      {children}
    </div>
  );
}

export default Container;
