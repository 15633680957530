import { BREAKPOINTS } from '@deardigital/shared/constants';

export function getNextBreakpoint(breakpoint: keyof typeof BREAKPOINTS): [keyof typeof BREAKPOINTS, number] | undefined {
  const keys = Object.keys(BREAKPOINTS) as Array<keyof typeof BREAKPOINTS>;
  const index = keys.indexOf(breakpoint);
  if (index === -1 || index === keys.length - 1) {
    return undefined;
  }
  const nextKey = keys[index + 1];
  return [nextKey, BREAKPOINTS[nextKey]];
}
