import { LinkInterface } from '@deardigital/shared/interfaces';
import BaseLink from './base-link';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface BlueLinkProps extends LinkInterface {}

export function BlueLink(props: BlueLinkProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 group ${props.wrapperClassName}`}
      className={`flex items-center justify-between rounded-button bg-blue-600 font-sans px-5 py-3 text-white ease-in-out duration-300 group-hover:bg-blue-500 ${props.className}`}
      leading={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full bg-blue-600 ease-in-out duration-300 group-hover:bg-blue-500 p-4 text-white"
          icon={props?.leadingIcon}
        />
      }
      trailing={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full  bg-blue-600 ease-in-out duration-300 group-hover:bg-blue-500 p-4 text-white"
          icon={props?.trailingIcon}
        />
      }
    />
  );
}

export default BlueLink;
