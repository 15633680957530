export async function emailSubmit(email: string, onSuccess: () => void, onError: (error: string) => void) {
    try {
      const postData = {
        email
      };
      
      const url = '/api/newsletter-subscribe';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      })
  
      if(!response.ok) {
        onError('error');
      }
      
      onSuccess();
    }
    catch(error) {
      throw new Error(`Something went wrong ${error}`)
    }
  }
  