'use client';

import { BREAKPOINTS } from '@deardigital/shared/constants';
import { LinkInterface, SectionInterface } from '@deardigital/shared/interfaces';
import { ColorShade } from '@deardigital/shared/constants';
import isEmpty from 'lodash.isempty';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import LinkRenderer from '../../link/link-renderer.component';
import ButtonRight from '../../swiper-button/button-left';
import ButtonLeft from '../../swiper-button/button-right';

export interface FeaturedCarouselProps<T> {
  items: T[];
  theme: ColorShade;
  section: SectionInterface;
  cta: LinkInterface[] | null;
  carouselCardSize?: number;
  CardComponent: React.ComponentType<T & { theme: ColorShade }>;
}

export function FeaturedCarousel<T>({
  items,
  theme,
  section,
  cta,
  CardComponent,
  carouselCardSize,
}: FeaturedCarouselProps<T>) {
  return (
    <Swiper
      spaceBetween={8}
      modules={[Navigation]}
      slidesPerView={5.2}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      className="carousel"
      watchOverflow={true}
      breakpoints={{
        [BREAKPOINTS.lg]: {
          slidesPerView: carouselCardSize,
          slidesOffsetBefore: 100,
          slidesOffsetAfter: 100,
        },
        [BREAKPOINTS.sm]: {
          slidesPerView: 2.5,
          spaceBetween: 12,
        },
        [BREAKPOINTS.xs]: {
          slidesPerView: 1.5,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
        },
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          <CardComponent {...item} theme={theme} />
        </SwiperSlide>
      ))}
      <div className="flex gap-4 justify-center md:justify-end mt-5 md:mt-10 md:mr-24">
        {cta && !isEmpty(cta) && (
          <div>{cta.map((item, key) => item && <LinkRenderer key={key} {...item} section={section} />)}</div>
        )}
        <ButtonRight section={section} />
        <ButtonLeft section={section} />
      </div>
    </Swiper>
  );
}

export default FeaturedCarousel;
