import { LinkInterface } from '@deardigital/shared/interfaces';
import IconRenderer from '../icons/icon-renderer.component';
import BaseLink from './base-link';

/* eslint-disable-next-line */
export interface WhiteOutlineLinkProps extends LinkInterface { }

export function WhiteOutlineLink(props: WhiteOutlineLinkProps) {
  return (
    <BaseLink
    {...props}
      wrapperClassName={`flex space-x-3 items-center ${props.wrapperClassName}`}
      className={`border border-white py-3 px-5 rounded-lg text-white flex font-sans  justify-between items-center ${props.className}`}
      leading={<IconRenderer className='flex h-12 w-12 items-center justify-between rounded-full border border-white p-4 text-white' icon={props?.leadingIcon} />}
      trailing={<IconRenderer className='flex h-12 w-12 items-center justify-between rounded-full border border-white p-4 text-white' icon={props?.trailingIcon} />}
    />
  );
}

export default WhiteOutlineLink;
