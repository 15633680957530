import { LinkInterface } from '@deardigital/shared/interfaces';
import BaseLink from './base-link';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface IndigoOutlineLinkProps extends LinkInterface { }

export function IndigoOutlineLink(props: IndigoOutlineLinkProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-4 ${props.wrapperClassName}`}
      className={`border border-indigo-800 py-3 px-5 rounded-lg text-indigo-800 flex justify-between items-center ${props.className}`}
      leading={<IconRenderer className='flex h-12 w-12 items-center justify-between rounded-full border border-indigo-800 p-4 text-indigo-800' icon={props?.leadingIcon} />}
      trailing={<IconRenderer className='flex h-12 w-12 items-center justify-between rounded-full border border-indigo-800 p-4 text-indigo-800' icon={props?.trailingIcon} />}
    />
  );
}

export default IndigoOutlineLink;
