import { SectionInterface } from '@deardigital/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface SectionProps extends SectionInterface {
  children: ReactNode;
  className?: string;
}

export function Section({ theme, children, className = '', slug, spacing, badge }: SectionProps) {
  // If there is a badge, we only add spacing to the bottom because the badge has its own spacing
  const newSpacing = spacing?.replace(/pt-/g, '');
  const bottomPadding = newSpacing?.replace(/py-/g, 'pb-');
  const sectionSpacing = !isEmpty(badge) ? bottomPadding : spacing;

  return (
    <section
      className={`section ${className} ${theme?.background} ${sectionSpacing}`}
      {...(slug ? { id: slug } : {})}
    >
      {children}
    </section>
  );
}

export default Section;