import { LinkInterface } from '@deardigital/shared/interfaces';
import BaseLink from './base-link';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface IndigoFilterOutlineLinkProps extends LinkInterface {}

export function IndigoFilterOutlineLink(props: IndigoFilterOutlineLinkProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 group ${props.wrapperClassName}`}
      className={`filter-badge text-sm font-sans py-4 text-indigo-800 border border-indigo-800 ease-in-out duration-300 group-hover:bg-indigo-800 hover:text-white ${props.className}`}
      leading={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full bg-indigo-800 ease-in-out duration-300 group-hover:bg-indigo-800 p-4 text-white"
          icon={props?.leadingIcon}
        />
      }
      trailing={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full  bg-indigo-800 ease-in-out duration-300 group-hover:bg-indigo-800 p-4 text-white"
          icon={props?.trailingIcon}
        />
      }
    />
  );
}

export default IndigoFilterOutlineLink;
