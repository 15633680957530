import { ContentFeaturedBlockBaseInterface } from '@deardigital/shared/interfaces';
import { storyblokEditable } from '@storyblok/react';
import isEmpty from 'lodash.isempty';
import SectionBadge from '../../badge/section-badge/section-badge';
import Container from '../../container/container';
import Section from '../../section/section';

/* eslint-disable-next-line */
export interface ContentFeaturedBlockProps extends ContentFeaturedBlockBaseInterface {
  children?: React.ReactNode;
}

export function ContentFeaturedBlockBase(props: ContentFeaturedBlockProps) {
  return (
    <Section {...props.section}>
      <div {...(props._editable ? { ...storyblokEditable(props as any) } : {})}>
        {props.section?.badge && !isEmpty(props.section?.badge) && (
          <div className={`mx-5 md:mx-[108px] ${props.section?.spacing || ''} !pb-4`}>
            {props.section.badge.map((item, key) => item && <SectionBadge key={key} {...item} {...props.section} />)}
          </div>
        )}
        {props.title && (
          <Container>
            <h2
              className={`${props.section.theme.color.heading} font-bold text-3xl text-center lg:text-5xl mb-4 md:mb-10`}
            >
              {props.title}
            </h2>
          </Container>
        )}
        {props.children}
      </div>
    </Section>
  );
}

export default ContentFeaturedBlockBase;
