import IconRenderer from '../icons/icon-renderer.component';
import BaseLink from './base-link';
import { LinkInterface } from '@deardigital/shared/interfaces';
import { LINK_TYPES } from '@deardigital/shared/constants';

export interface PrimaryOutlineLinkProps extends LinkInterface {}

export function PrimaryOutlineLink(props: PrimaryOutlineLinkProps) {
  const buttonClass = props.section?.theme.button?.primary;

  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 group ${props.wrapperClassName}`}
      className={`flex items-center border justify-between rounded-button font-sans px-5 py-3 ease-in-out duration-300
        ${props.section?.theme.background}
        ${buttonClass?.outlineDefault}
        ${buttonClass?.textOutline}
        ${buttonClass?.outlineHover}
        ${buttonClass?.outlineHoverText}
        ${buttonClass?.outlineActive}
        ${buttonClass?.outlineHoverBackground}
        ${buttonClass?.outlineActiveBackground}
        ${props.className}
      `}
      leading={
        <IconRenderer
          className={`
            flex h-12 w-12 items-center justify-between rounded-full ease-in-out duration-300 border p-4
            ${props.section?.theme.background}
            ${buttonClass?.outlineDefault}
            ${buttonClass?.textOutline}
            ${buttonClass?.outlineHover}
            ${buttonClass?.outlineActive}
            ${buttonClass?.outlineHoverBackground}
            ${buttonClass?.outlineActiveBackground}
            ${props.className}
          `}
          icon={props?.leadingIcon}
        />
      }
      trailing={
        <IconRenderer
          className={`${props.type === LINK_TYPES.web ? '-rotate-45' : ''} flex h-12 w-12 items-center justify-between rounded-full ease-in-out duration-300 border p-4
            ${props.section?.theme.background}
            ${buttonClass?.outlineDefault}
            ${buttonClass?.textOutline}
            ${buttonClass?.outlineHover}
            ${buttonClass?.outlineActive}
            ${buttonClass?.outlineHoverBackground}
            ${buttonClass?.outlineActiveBackground}
            ${props.className}
          `}
          icon={props?.trailingIcon}
        />
      }
    />
  );
}

export default PrimaryOutlineLink;
