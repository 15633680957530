import IconRenderer from '../icons/icon-renderer.component';
import BaseLink from './base-link';
import { LinkInterface } from '@deardigital/shared/interfaces';
import { ButtonColors, LINK_TYPES } from '@deardigital/shared/constants';

export interface PrimaryLinkProps extends LinkInterface {}

export function PrimaryLink(props: PrimaryLinkProps) {
  let buttonClass: ButtonColors | undefined = props.section?.theme.button?.primary;
  
  if (props.theme) {
    buttonClass = props.theme.button?.primary;
  } else {
    buttonClass = props.section?.theme.button?.primary
  }

  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 group ${props.wrapperClassName}`}
      className={`
        flex items-center justify-between rounded-button font-sans px-5 py-3 ease-in-out duration-300
        ${buttonClass?.bgDefault}
        ${buttonClass?.textDefault}
        ${buttonClass?.bgHover}
        ${buttonClass?.bgActive}
        ${props.className}
      `}
      leading={
        <IconRenderer
          className={`
            flex h-12 w-12 items-center justify-between rounded-full ease-in-out duration-300 p-4
            ${buttonClass?.bgDefault}
            ${buttonClass?.textDefault}
            ${buttonClass?.bgHover}
            ${buttonClass?.bgActive}
            ${props.className}
          `}
          icon={props?.leadingIcon}
        />
      }
      trailing={
        <IconRenderer
          className={`${props.type === LINK_TYPES.web ? '-rotate-45' : ''} flex h-12 w-12 items-center justify-between rounded-full ease-in-out duration-300 p-4
            ${buttonClass?.bgDefault}
            ${buttonClass?.textDefault}
            ${buttonClass?.bgHover}
            ${buttonClass?.bgActive}
            ${props.className}
          `}
          icon={props?.trailingIcon}
        />
      }
    />
  );
}

export default PrimaryLink;
