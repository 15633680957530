import { ICONS } from '@deardigital/shared/constants';
import { GlobalInterface, LinkInterface } from '@deardigital/shared/interfaces';
import * as Accordion from '@radix-ui/react-accordion';
import IconRenderer from '../../icons/icon-renderer.component';
import LinkRenderer from '../../link/link-renderer.component';

export interface NavigationAccordionItemProps {
  section: {
    title: string | null;
    links: LinkInterface[] | null;
  };
  globals: GlobalInterface;
  value: number;
}

export function NavigationAccordionItem({ section, globals, value }: NavigationAccordionItemProps) {
  return (
    <Accordion.Item className={`accordion border-0 border-b border-b-blue-50`} value={`${value}`}>
      <Accordion.Header>
        <Accordion.Trigger
          className={`group flex w-full items-center justify-between py-5 text-lg font-medium ${globals.theme?.color.text}`}
        >
          <span>{section.title}</span>
          <IconRenderer
            icon={ICONS.plusIcon}
            aria-hidden
            className={`transition-transform duration-300 group-data-[state=open]:rotate-45`}
          />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown overflow-hidden p-0">
        <ul>
          {section?.links &&
            section?.links?.map((link, index) => (
              <li className="mb-5" key={index}>
                <LinkRenderer className={globals.theme?.color.text} size="text-sm" {...link} />
              </li>
            ))}
        </ul>
      </Accordion.Content>
    </Accordion.Item>
  );
}
