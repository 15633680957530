import { ICONS, IconType } from '@deardigital/shared/constants';
import { MappingFunction } from '@deardigital/shared/interfaces';
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { ReactComponent as PlusIcon } from '../icons/plus-icon.svg';
import { ReactComponent as PlusIconSmall } from '../icons/plus-icon-small.svg';
import { ReactComponent as CloseIcon } from '../icons/close-icon.svg';
import { ReactComponent as NavbarToggle } from '../icons/navbar-toggle.svg';
import { SVGProps } from 'react';

/* eslint-disable-next-line */
export interface IconRendererProps extends SVGProps<SVGSVGElement> {
  icon?: IconType | undefined | null;
}

const componentMapping = new Map<IconType, MappingFunction<SVGProps<SVGSVGElement>, JSX.Element>>([
  [ICONS.arrowLeft, (props) => <ArrowLeft {...props} />],
  [ICONS.arrowRight, (props) => <ArrowRight {...props} />],
  [ICONS.plusIcon, (props) => <PlusIcon {...props} />],
  [ICONS.plusIconSmall, (props) => <PlusIconSmall {...props} />],
  [ICONS.closeIcon, (props) => <CloseIcon {...props} />],
  [ICONS.navbarToggle, (props) => <NavbarToggle {...props} />],
]);

export function IconRenderer({ icon, ...restProps}: IconRendererProps) {
  if (!icon) {
    return null
  }

  const componentControl = componentMapping.get(icon);
  if (!componentControl) {
    return null
  }

  return componentControl(restProps);
}

export default IconRenderer;
