'use client';

import { ContentFeaturedPodcastInterface } from '@deardigital/shared/interfaces';
import isEmpty from 'lodash.isempty';
import LinkRenderer from '../../link/link-renderer.component';
import PodcastCard from '../../podcast/podcast-card';
import ContentFeaturedBlockBase from './featured-block-base';
import FeaturedCarousel from './featured-carousel';

/* eslint-disable-next-line */
export interface ContentFeaturedPodcastProps extends ContentFeaturedPodcastInterface {}

export function ContentFeaturedPodcast(props: ContentFeaturedPodcastInterface) {
  return (
    <ContentFeaturedBlockBase {...props}>
      <div>
        {props.podcasts.length > 4 ? (
          <FeaturedCarousel
            CardComponent={PodcastCard}
            items={props.podcasts}
            cta={props.cta}
            theme={props.section.theme}
            section={props.section}
            carouselCardSize={5.2}
          />
        ) : (
          <>
            <div className="grid grid-cols-12 gap-x-3 gap-y-10 mx-5 md:mx-24">
              {props.podcasts.map((podcast, key) => (
                <div key={key} className="col-span-12 md:col-span-6 lg:col-span-3">
                  <PodcastCard {...podcast} theme={props.section.theme} />
                </div>
              ))}
            </div>
            <div className='mx-5 md:mx-10 flex justify-center mt-8'>
              {props.cta && !isEmpty(props.cta) && (
                <div>
                  {props.cta.map((item, key) => item && <LinkRenderer key={key} {...item} section={props.section} />)}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </ContentFeaturedBlockBase>
  );
}

export default ContentFeaturedPodcast;
