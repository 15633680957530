'use client';

import { WorkCardInterface } from '@deardigital/shared/interfaces';
import Link from 'next/link';
import ImageRenderer from '../image-renderer/image-renderer';
import TagLink from '../link/tag-link';
import { ColorShade } from '@deardigital/shared/constants';

/* eslint-disable-next-line */
export interface WorkCardProps extends WorkCardInterface {
  theme?: ColorShade | null;
}

export function WorkCard({ thumbnail, title, services, slug, theme }: WorkCardProps) {
  return (
    <div>
      <div className="relative p-2 pt-0">
        <figure className="aspect-square">
          {thumbnail && thumbnail[0] && (
            <ImageRenderer loadingState={'lazy'} className={`object-cover rounded-2xl w-full`} image={thumbnail[0]} />
          )}
        </figure>
        <div className="pt-4">
          <div>
            <h4 className={`${theme?.color.heading} !leading-7`}>
              <Link className="font-sans text-lg after:absolute after:inset-0" href={`/${slug}`}>
                {title && title}
              </Link>
            </h4>
          </div>
          <div className="flex flex-wrap pt-3 gap-2">
            {services.slice(0, 5).map((service, key) => (
              <TagLink key={key} link={`/${service.slug}`} label={service.title} theme={theme} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default WorkCard;
