'use client';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { emailSubmit } from './email-submit';
import { validationSchema } from './validation';
import { ColorShade } from '@deardigital/shared/constants';

interface Values {
  email: string;
}

export interface NewsletterSubscribeProps {
  placeholder: string;
  theme?: ColorShade;
  id?: string;
  privacyText: string;
}

export function NewsletterSubscribe(props: NewsletterSubscribeProps) {
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const togglePrivacyText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleSubmit = (values: Values, { resetForm }: FormikHelpers<Values>) => {
    emailSubmit(
      values.email,
      () => {
        setMessage('Thanks for subscribing');
        resetForm();
        setEmail(''); // Reset email state after successful submission
      },
      (error: string) => {
        setMessage(error);
      },
    );
  };

  return (
    <div>
      <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched }) => (
          <Form className="">
            {message && <p className="text-sm text-green-400">{message}</p>}
            {errors.email && touched.email ? <div className="text-sm text-red-500 mb-2">{errors.email}</div> : null}

            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <div className="relative py-7 flex justify-between items-center">
              <Field
                id={`email_${props.id}`}
                name="email"
                placeholder={props.placeholder}
                className={`absolute w-full pl-5 lg:pl-6 pr-12 py-4 border ${props.theme?.color.text} ${props.theme?.input.border} rounded-full focus:outline-none bg-transparent focus:ring-0  placeholder:font-sans placeholder:text-sm ${props.theme?.input.placeholderColor}`}
                type="email"
                value={email}
                onChange={togglePrivacyText}
              />

              <button
                type="submit"
                aria-label="submit"
                className="absolute top-0 right-0 h-full aspect-square items-center justify-center flex"
              >
                <ArrowRight className={`h-3 w-3 ${props.theme?.color.text}`} />
              </button>
            </div>

            {email && (
              <div className={`${props.theme?.editor}`}>
                <div
                  className={`block text-xs text-center mt-4 ${props.theme?.color.text}`}
                  dangerouslySetInnerHTML={{ __html: props.privacyText }}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewsletterSubscribe;
