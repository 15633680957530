import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import Link from 'next/link';
import { NavigationItemSubMenu } from './navigation-item-submenu';

export interface NavigationItemMobileProps {
  item: NavigationItemInterface;
  showMenuWithNav: (item: NavigationItemInterface) => boolean;
  isActivePage: (link: string | null | undefined) => boolean;
  globals: GlobalInterface;
}

export function NavigationItemMobile({ item, showMenuWithNav, isActivePage, globals }: NavigationItemMobileProps) {
  return (
    <div>
      {item.cards && item.cards.length > 0 ? (
        <NavigationItemSubMenu item={item} showMenuWithNav={showMenuWithNav} globals={globals} />
      ) : (
        <Link
          href={item.link?.link ?? ''}
          className={`${globals.theme?.color.text} block py-1.5 text-2xl`}
          {...(isActivePage(item.link?.link) ? { active: true } : {})}
        >
          <div>{item.link?.label}</div>
        </Link>
      )}
    </div>
  );
}
