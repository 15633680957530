'use client';

import { LinkInterface } from '@deardigital/shared/interfaces';
import LinkRenderer from '../link/link-renderer.component';

/* eslint-disable-next-line */
export interface NavigationLinkProps extends LinkInterface {}

export function NavigationLink(props: NavigationLinkProps) {
  // const pathname = usePathname();
  const isActive = false;
  // const isActive = pathname.indexOf(props.link) > -1;
  const className = `text-3xl lg:text-base block py-2 pl-3 pr-4 rounded ${
    isActive
      ? 'text-white bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 lg:dark:text-blue-500'
      : 'text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 lg:p-0 lg:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700'
  }`;

  return (
    <li className='lg:self-center'>
      <LinkRenderer {...props} className={className} section={props.section} {...(isActive ? { 'aria-current': 'page' } : {})}>
        {props.label}
      </LinkRenderer>
    </li>
  );
}

export default NavigationLink;
