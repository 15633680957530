'use client';

import { ContentWhitepaperFormInterface } from '@deardigital/shared/interfaces';
import SectionBadge from '../badge/section-badge/section-badge';
import KlaviyoForm from '../form/klaviyo-form';
import Section from '../section/section';
import isEmpty from 'lodash.isempty';

export interface ContentWhitepaperFormProps extends ContentWhitepaperFormInterface {}

export function ContentWhitepaperForm(props: ContentWhitepaperFormProps) {
  return (
    <Section {...props.section}>
      <div className="container mx-auto px-5">
        {!isEmpty(props.section?.badge) && props.section?.badge && (
          <div className={`${props.section?.spacing || ''} !pb-4`}>
            {props.section.badge.map((item, key) => item && <SectionBadge key={key} {...item} {...props.section} />)}
          </div>
        )}
        <KlaviyoForm
          klaviyoFormId={props.whitepaper.klaviyoFormId}
          thumbnail={props.whitepaper.thumbnail}
          thankYouMessage={props.thankYouMessage}
          section={props.section}
          text={props.text}
        />
      </div>
    </Section>
  );
}
