import { MediaInterface } from '@deardigital/shared/interfaces';
import { Dispatch, SetStateAction } from 'react';
import ImageRenderer from '../../image-renderer/image-renderer';

interface VideoPosterProps {
  poster?: MediaInterface | null;
  isPlaying: boolean;
  setPlaying?: Dispatch<SetStateAction<boolean>>;
  className?: string;
  loadingState?: 'lazy' | 'eager';
}

export function VideoPoster({ isPlaying: playing, poster, className, loadingState }: VideoPosterProps) {
  if (playing || !poster?.url) {
    return null;
  }

  const preloadImage = loadingState === 'eager' ? 'preload' : 'none';
  const fetchPriority = loadingState === 'eager' ? 'high' : 'auto';

  return (
    <ImageRenderer
      image={poster}
      className={`absolute left-0 top-0 h-full w-full object-cover ${className}`}
      loadingState={loadingState}
      fetchPriority={fetchPriority}
      preload={preloadImage}
    />
  );
}

export default VideoPoster;
