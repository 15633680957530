import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { NavigationItemMobile } from './navigation-item-mobile';

export interface NavigationListMobileProps {
  globals: GlobalInterface | null;
  showMenuWithNav: (item: NavigationItemInterface) => boolean;
  isActivePage: (link: string | null | undefined) => boolean;
}

export function NavigationListMobile({ globals, showMenuWithNav, isActivePage }: NavigationListMobileProps) {
  return (
    <ul className="flex flex-col gap-8 list-none">
      {globals?.header?.navigation?.map((item, key) => (
        <li key={key}>
          <NavigationItemMobile
            item={item}
            globals={globals}
            showMenuWithNav={showMenuWithNav}
            isActivePage={isActivePage}
          />
        </li>
      ))}
    </ul>
  );
}
