import { ColorShade } from '@deardigital/shared/constants';
import BaseLink from './base-link';

/* eslint-disable-next-line */
export interface TagLinkProps {
  link: string;
  label: string;
  theme: ColorShade | null | undefined;
}

export function TagLink(props: TagLinkProps) {
  return (
    <BaseLink
      type="page"
      linkStyle="text-indigo"
      link={props.link}
      label={props.label}
      className={`inline-block items-center rounded-xl ${props.theme?.color.badgeBackground} ${props.theme?.color.badgeText} px-4 py-2 text-xs`}
    />
  );
}

export default TagLink;
