import { LinkInterface } from '@deardigital/shared/interfaces';
import BaseLink from './base-link';
import IconRenderer from '../icons/icon-renderer.component';

export interface BlueLightProps extends LinkInterface {}

export function BlueLight(props: BlueLightProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`space-x-3 group ${props.wrapperClassName} bg-blue-200 text-indigo-800 text-sm rounded-3xl py-2 px-4 ease-in-out duration-300`}
      leading={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full bg-blue-200 ease-in-out duration-300 group-hover:bg-blue-500 p-4 text-indigo-800"
          icon={props?.leadingIcon}
        />
      }
      trailing={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full  bg-blue-200 ease-in-out duration-300 group-hover:bg-blue-500 p-4 text-indigo-800"
          icon={props?.trailingIcon}
        />
      }
    />
  );
}

export default BlueLight;
