import { BadgeInterface } from '@deardigital/shared/interfaces';
import BaseBadge from '../base-badge';

export interface BlueSectionBadgeProps extends BadgeInterface {
  spacing?: string | null;
  className?: string;
}

export function BlueSectionBadge({ className, label }: BlueSectionBadgeProps) {
  return <BaseBadge className={`${className} px-5 py-2 text-sm bg-blue-200 text-indigo-800`}>{label}</BaseBadge>;
}

export default BlueSectionBadge;
