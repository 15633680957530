import { ICONS } from '@deardigital/shared/constants';
import IconRenderer from '../icons/icon-renderer.component';
import { SectionInterface } from '@deardigital/shared/interfaces';

export interface ButtonLeftProps {
  section: SectionInterface;
}

export function ButtonLeft({ section }: ButtonLeftProps) {
  const buttonClass = section?.theme.button?.primary;

  return (
    <div className="swiper-button-prev">
      <IconRenderer
        className={`
          flex h-12 w-12 items-center justify-between rounded-full ease-in-out duration-300 cursor-pointer p-4
          border
          ${buttonClass?.outlineDefault}
          ${buttonClass?.textOutline}
          ${buttonClass?.outlineHoverBackground}
          ${buttonClass?.outlineHoverText}
          ${buttonClass?.bgHover}
          ${buttonClass?.bgActive}
        `}
        icon={ICONS.arrowLeft}
      />
    </div>
  );
}

export default ButtonLeft;
