'use client';
import { ContentBuyBoxInterface } from '@deardigital/shared/interfaces';
import { useState } from 'react';

export type ContentBuyBoxProps = ContentBuyBoxInterface;

function ContentBuyBox({ product }: ContentBuyBoxProps) {
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string }>({});

  const handleOptionChange = (optionName: string, value: string) => {
    setSelectedOptions({ ...selectedOptions, [optionName]: value });
  };
  if (!product) return null;

  return (
    <div className="bg-white">
      <div className="p-5 lg:p-10">
        {/* Image gallery */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
          <div className="col-span-1 aspect-square h-fit lg:sticky lg:top-3 lg:col-span-6 lg:aspect-auto">
            {product.featuredImage && (
              <img
                alt="featuredImage"
                src={product.featuredImage}
                className="h-full w-full object-cover object-center rounded-md"
              />
            )}
            <div className="grid grid-cols-2 pt-6 gap-6">
              {product.productImages.map((image, index) => (
                <img
                  key={index}
                  alt={image.src}
                  src={image.src}
                  className="h-full w-full object-cover object-center rounded-md"
                />
              ))}
            </div>
          </div>
          {/* Product info */}
          <div className="w-full col-span-1 lg:col-span-6">
            <div className="flex justify-between w-full">
              <h1 className="text-4xl tracking-tight text-gray-900">{product.title}</h1>
              <div className="flex gap-2">
                <p className="text-2xl tracking-tight text-gray-300 line-through ">
                  €{product.comapreAtPrice.minVariantPrice.amount}
                </p>
                <p className="text-2xl tracking-tight text-gray-900">€{product.priceRange.minVariantPrice.amount}</p>
              </div>
            </div>
            <div className="py-6">
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{product.description}</p>
                <h3 className="text-md font-light text-gray-900">
                  <span className="font-bold">Vendor: </span>
                  {product.vendor}
                </h3>
              </div>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              {product.option.map((option, index) => (
                <div key={index} className="mt-4">
                  <label className="block text-md text-gray-700">{option.name}</label>
                  <div className="mt-2 flex flex-wrap gap-2">
                    {option.values.map((value, idx) => (
                      <button
                        key={idx}
                        type="button"
                        className={`px-4 py-2 rounded-full border ${
                          selectedOptions[option.name] === value
                            ? 'bg-black text-white border-black'
                            : 'bg-white text-gray-700 border-gray-300'
                        }`}
                        onClick={() => handleOptionChange(option.name, value)}
                      >
                        {value}
                      </button>
                    ))}
                  </div>
                </div>
              ))}

              <div className="mt-4">
                <label htmlFor="quantity" className="text-md text-gray-700">
                  Quantity
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm rounded-md"
                  defaultValue={1}
                />
              </div>

              <button
                type="submit"
                className="mt-10 flex w-full items-center justify-center rounded-md border-transparent bg-black px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-black border-2 border-black hover:border-2 hover:border-black transition-all focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 shadow-lg"
              >
                Add to bag
              </button>
              <div className="p-10 bg-gray-100 rounded-lg mt-6 text-center">
                <h3 className="text-md font-light text-gray-900">
                  <span className="font-bold">BUY 1 GET 1 FREE</span>
                </h3>
                <p>
                  Add at least{' '}
                  <b>
                    <u>2 items</u>
                  </b>{' '}
                  to your cart and the discount will be applied automatically, you can mix &amp; match products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentBuyBox;
