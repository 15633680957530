import { MediaInterface } from '@deardigital/shared/interfaces';
import React from 'react';

interface ImageRendererProps {
  image: MediaInterface | null;
  className?: string;
  loadingState?: 'lazy' | 'eager';
  fetchPriority?: 'auto' | 'high' | 'low';
  preload?: 'preload' | 'none';
}

export const ImageRenderer: React.FC<ImageRendererProps> = ({
  image,
  className = '',
  loadingState,
  fetchPriority = 'auto',
  preload
}) => {
  if (!image) {
    return null;
  }

  const breakpointLg = 1024;

  // Set up responsive image sources using picture element
  return (
    <picture>
      {/* Smaller images for mobile and below lg breakpoint */}
      <source
        media={`(max-width: ${breakpointLg - 1}px)`}
        srcSet={image.srcSet || ''}
        sizes={image.sizes || ''}
      />
      {/* Larger images for desktop and above lg breakpoint */}
      <source
        media={`(min-width: ${breakpointLg}px)`}
        srcSet={image.srcSetLg || image.srcSet || ''}
        sizes={image.sizes || image.sizes || ''}
      />
      {/* Default img element for browsers that do not support picture */}
      <img
        src={image.url}
        className={`${image.ratio} ${image.ratioLg} ${className}`}
        alt={image.alt || image.url || ''}
        loading={loadingState}
        fetchPriority={fetchPriority}
        rel={preload}
      />
    </picture>
  );
};

export default ImageRenderer;
