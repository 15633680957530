'use client';

/* eslint-disable-next-line */
import { ContentCalendlyInterface } from '@deardigital/shared/interfaces';
import { storyblokEditable } from '@storyblok/react';
import isEmpty from 'lodash.isempty';
import { InlineWidget } from 'react-calendly';
import SectionBadge from '../../badge/section-badge/blue-section-badge';
import Section from '../../section/section';

export interface ContentCalendlyProps extends ContentCalendlyInterface {}

export function ContentCalendly(props: ContentCalendlyProps) {
  if (!props.calendlyLink?.link) return null;

  return (
    <Section {...props.section}>
      <div className="container mx-auto px-5" {...(props._editable ? { ...storyblokEditable(props as any) } : {})}>
        {props.section.badge && !isEmpty(props.section.badge) && (
          <div className={`${props.section.spacing} flex flex-wrap gap-4`}>
            {!isEmpty(props.section?.badge) && props.section?.badge && (
              <div className={`${props.section?.spacing || ''} !pb-4`}>
                {props.section.badge.map(
                  (item, key) => item && <SectionBadge key={key} {...item} {...props.section} />,
                )}
              </div>
            )}
          </div>
        )}
        <div className="grid grid-flow-row lg:grid-cols-12">
          <div className={`lg:col-start-2 lg:col-end-12 lg:col-span-10`}>
            <InlineWidget url={props.calendlyLink.link} />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContentCalendly;
