'use client';

import { CollapseItemInterface, SectionInterface } from '@deardigital/shared/interfaces';
import * as Accordion from '@radix-ui/react-accordion';
import LinkRenderer from '../../link/link-renderer.component';
import IconRenderer from '../../icons/icon-renderer.component';
import { ICONS } from '@deardigital/shared/constants';

export interface CollapseItemsProps {
  items: CollapseItemInterface[];
  section: SectionInterface;
}

export function Collapse({ items, section }: CollapseItemsProps) {
  return (
    <Accordion.Root
      type="single"
      collapsible
      className={`divide-y rounded-lg border-0 bg-transparent lg:w-1/2 ${section.theme.color.divide}`}
    >
      {items.map((item, index) => (
        <Accordion.Item value={`${index}`} key={index}>
          <Accordion.Header>
            <Accordion.Trigger
              className={`group mx-0 flex w-full items-center justify-between px-0 py-7 font-sans text-2xl lg:text-3xl ${section.theme.color.heading} border-1 focus:ring-0 ${section.theme.color.divide} bg-transparent hover:bg-transparent`}
            >
              <span>{item.title}</span>
              <IconRenderer
                icon={ICONS.plusIcon}
                aria-hidden
                className="transition-transform duration-300 group-data-[state=open]:rotate-45"
              />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown mx-0 overflow-hidden px-0 font-sans text-base first:rounded-t-lg last:rounded-b-lg">
            <div className="pb-5">
              <p className={`mb-2 ${section.theme.color.text}`}>{item.description}</p>
              {item.links?.map((link, index) => (
                <div key={index} className="mt-5">
                  <LinkRenderer {...link} section={section} />
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}
