'use client';

import { ContentFeaturedBlogsInterface } from '@deardigital/shared/interfaces';
import BlogCard from '../../blog/blog-card';
import ContentFeaturedBlockBase from './featured-block-base';
import FeaturedCarousel from './featured-carousel';

/* eslint-disable-next-line */
export interface ContentFeaturedBlogsProps extends ContentFeaturedBlogsInterface {}

export function ContentFeaturedBlogs(props: ContentFeaturedBlogsInterface) {
  return (
    <ContentFeaturedBlockBase {...props}>
      <div>
        {props.blogs.length > 4 ? (
          <FeaturedCarousel
            CardComponent={BlogCard}
            items={props.blogs}
            cta={props.cta}
            theme={props.section.theme}
            section={props.section}
            carouselCardSize={5.2}
          />
        ) : (
          <div className="grid grid-cols-12 gap-x-3 gap-y-10 mx-5 md:mx-24">
            {props.blogs.map((blog, key) => (
              <div key={key} className="col-span-12 md:col-span-6 lg:col-span-3">
                <BlogCard {...blog} theme={props.section.theme} />
              </div>
            ))}
          </div>
        )}
      </div>
    </ContentFeaturedBlockBase>
  );
}

export default ContentFeaturedBlogs;
