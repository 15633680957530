import { LinkInterface } from '@deardigital/shared/interfaces';
import IconRenderer from '../icons/icon-renderer.component';
import BaseLink from './base-link';

/* eslint-disable-next-line */
export interface TextLinkProps extends LinkInterface { }

export function TextWhiteLink(props: TextLinkProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 items-center font-medium text-lg ${props.wrapperClassName}`}
      className={`text-white ${props.className}`}
      leading={<IconRenderer icon={props?.leadingIcon} />}
      trailing={<IconRenderer icon={props?.trailingIcon} />}
    />
  );
}

export default TextWhiteLink;
