import { LinkInterface } from '@deardigital/shared/interfaces';
import IconRenderer from '../icons/icon-renderer.component';
import BaseLink from './base-link';
import { LINK_TYPES } from '@deardigital/shared/constants';

/* eslint-disable-next-line */
export interface TextLinkProps extends LinkInterface {
  size?: string;
}

export function TextLink(props: TextLinkProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 items-center font-medium ${props.size ? props.size : 'text-lg'} ${props.wrapperClassName}`}
      className={`${props.section?.theme.button?.textLink.textColor} ${props.className} hover:underline`}
      leading={<IconRenderer className={`${props.section?.theme.button?.textLink.textColor} ${props.className} flex h-3 w-3 items-center justify-between hover:underline`} icon={props?.leadingIcon} />}
      trailing={<IconRenderer className={`${props.section?.theme.button?.textLink.textColor} ${props.className} flex h-3 w-3 items-center justify-between hover:underline ${props.type === LINK_TYPES.web ? '-rotate-45' : ''}`} icon={props?.trailingIcon} />}
    />
  );
}

export default TextLink;
