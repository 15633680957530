import { LinkInterface } from '@deardigital/shared/interfaces';
import BaseLink from './base-link';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface IndigoFilterLinkProps extends LinkInterface {}

export function IndigoFilterLink(props: IndigoFilterLinkProps) {
  return (
    <BaseLink
      {...props}
      wrapperClassName={`flex space-x-3 group ${props.wrapperClassName}`}
      className={`filter-badge bg-indigo-800 text-sm font-sans text-white ease-in-out duration-300 group-hover:bg-indigo-500 ${props.className}`}
      leading={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full bg-indigo-800 ease-in-out duration-300 group-hover:bg-indigo-500 p-4 text-white"
          icon={props?.leadingIcon}
        />
      }
      trailing={
        <IconRenderer
          className="flex h-12 w-12 items-center justify-between rounded-full  bg-indigo-800 ease-in-out duration-300 group-hover:bg-indigo-500 p-4 text-white"
          icon={props?.trailingIcon}
        />
      }
    />
  );
}

export default IndigoFilterLink;
