import { GlobalInterface, NavigationItemCardInterface } from '@deardigital/shared/interfaces';
import ImageRenderer from '../../image-renderer/image-renderer';
import LinkRenderer from '../../link/link-renderer.component';

export interface NavigationItemCardProps {
  card: NavigationItemCardInterface;
  globals?: GlobalInterface;
}

export function NavigationItemCard({ card, globals }: NavigationItemCardProps) {
  if (!card || !card.cta) {
    return null;
  }

  return (
    <div className={`relative w-full rounded-lg ${globals?.theme?.color.cardBackground} overflow-hidden flex flex-col lg:flex-row`}>
      <LinkRenderer {...card.cta} className="absolute inset-0 opacity-0" aria-label={`Navigate to ${card.title}`} />
      <div className="w-full lg:w-1/2">
        {card.image && <ImageRenderer loadingState={'lazy'} className="aspect-4/3 w-full object-cover max-h-72 h-full" image={card.image} />}
      </div>
      <div className="p-4 md:py-12 w-full lg:w-1/2 lg:px-6 lg:py-4 flex items-center">
        <div className="grid gap-2">
          <p className={`text-2xl ${globals?.theme?.color.badgeText}`}>{card.title}</p>
          <p className={`text-lg font-medium ${globals?.theme?.color.badgeText}`}>{card.subTitle}</p>
        </div>
      </div>
    </div>
  );
}
