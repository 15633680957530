import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { NavigationAccordionItem } from './navigation-accordion';
import * as Accordion from '@radix-ui/react-accordion';

export interface NavigationSubMenuAccordionProps {
  item: NavigationItemInterface;
  globals: GlobalInterface;
}

export function NavigationSubMenuAccordion({ item, globals }: NavigationSubMenuAccordionProps) {
  return (
    <Accordion.Root type="single" collapsible className={`bg-transparent`}>
      {item?.cta && <NavigationAccordionItem globals={globals} section={item.cta} key={10} value={10} />}

      {item?.links &&
        item?.links?.map((section, index) => (
          <NavigationAccordionItem globals={globals} section={section} key={index} value={index} />
        ))}
    </Accordion.Root>
  );
}
