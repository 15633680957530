import { ICONS } from '@deardigital/shared/constants';
import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import IconRenderer from '../../icons/icon-renderer.component';
import { MenuItem } from '../menu-item';

export interface NavigationItemDeskProps {
  navItem: NavigationItemInterface;
  showMenuWithNav: (item: NavigationItemInterface) => boolean;
  index: number;
  isNavOpen?: boolean;
  setIsNavOpen?: (value: boolean) => void;
  globals: GlobalInterface;
}

interface ExpandableLinkProps {
  navItem: NavigationItemInterface;

  toggleNav: () => void;
  globals?: GlobalInterface;
}

const ExpandableLink: React.FC<ExpandableLinkProps> = ({ navItem, toggleNav, globals }) => (
  <button
    className={`block group lg:text-base ${globals?.theme?.color.text} ${globals?.theme?.color.textHover} font-medium lg:py-3`}
    onClick={toggleNav}
  >
    <p className="flex items-center gap-1">
      {navItem.link?.label}
      <span className="group-hover:-rotate-180 transition-transform duration-300">
        <IconRenderer icon={ICONS.plusIconSmall} className="w-3 h-3" />
      </span>
    </p>
  </button>
);

const SimpleLink: React.FC<{ navItem: NavigationItemInterface, globals: GlobalInterface }> = ({ navItem, globals }) => (
  <Link href={navItem.link?.link ?? ''}>
    <p className={`${globals.theme?.color.text} flex gap-1`}>{navItem.link?.label}</p>
  </Link>
);

export default function NavigationItemDesk({
  navItem,
  showMenuWithNav,
  index,
  globals,
}: NavigationItemDeskProps) {
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const nodeRef = useRef<HTMLDivElement>(null); // Ref for the component wrapper

  const toggleNav = () => {
    if (openItemId === index) {
      setOpenItemId(null);
    } else {
      setOpenItemId(index);
    }
  };

  useEffect(() => {
    // Handle click outside the element
    const handleClickOutside = (event: MouseEvent) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target as Node)) {
        setOpenItemId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  const hasSubMenu = showMenuWithNav(navItem);
  const isNavOpen = openItemId === index;

  return (
    <div ref={nodeRef} className="hidden lg:block">
      {hasSubMenu ? (
        <ExpandableLink navItem={navItem} toggleNav={toggleNav} globals={globals} />
      ) : (
        <SimpleLink navItem={navItem} globals={globals} />
      )}
      {hasSubMenu && isNavOpen && (
        <div>
          <MenuItem globals={globals} itemData={navItem} />
        </div>
      )}
    </div>
  );
}
