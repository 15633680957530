import { BadgeInterface } from '@deardigital/shared/interfaces';
import BaseBadge from '../base-badge';
import { ColorShade } from '@deardigital/shared/constants';

export interface SectionBadgeProps extends BadgeInterface {
  spacing?: string | null;
  theme: ColorShade;
  className?: string;
}

export function SectionBadge({ className, label, spacing, ...props }: SectionBadgeProps) {
  return (
    <BaseBadge
      className={`${className} ${props.theme.color.badgeBackground} ${props.theme.color.badgeText} mr-3`}
    >
      {label}
    </BaseBadge>
  );
}

export default SectionBadge;
