import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { NavigationItemCard } from './navigation-item-card';

export interface NavigationItemCardsGridProps {
  itemData: NavigationItemInterface;
  globals: GlobalInterface;
}

export function NavigationItemCardsGrid({ itemData, globals }: NavigationItemCardsGridProps) {
  return (
    <div className="grid gap-4 lg:grid-cols-2 lg:gap-6">
      {itemData?.cards?.map((card, index) => <NavigationItemCard key={index} card={card} globals={globals} />)}
    </div>
  );
}
