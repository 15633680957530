'use client';

import { LINK_TYPES, LinkTypeType } from '@deardigital/shared/constants';
import { LinkInterface, MappingFunction } from '@deardigital/shared/interfaces';
import Link from 'next/link';
import { sendGTMEvent } from '@next/third-parties/google';

/* eslint-disable-next-line */
export interface BaseLinkProps extends LinkInterface {
  wrapperClassName?: string;
  children?: React.ReactNode;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
}

function PageLink(props: BaseLinkProps) {
  return (
    <Link
      href={props.link}
      onClick={() => sendGTMEvent({ event: 'buttonClicked', value: props.label })}
      {...(props.wrapperClassName ? { className: props.wrapperClassName } : {})}
    >
      {props.leading && props.leading}
      <span className={props.className}>{props.children ?? props.label}</span>
      {props.trailing && props.trailing}
    </Link>
  );
}

function WebLink(props: BaseLinkProps) {
  return (
    <a href={props.link} {...(props.wrapperClassName ? { className: props.wrapperClassName } : {})}>
      {props.leading && props.leading}
      <span className={props.className}>{props.children ?? props.label}</span>
      {props.trailing && props.trailing}
    </a>
  );
}

function EmailLink(props: BaseLinkProps) {
  return (
    <a href={`mailto:${props.link}`}
      {...(props.wrapperClassName ? { className: props.wrapperClassName } : {})}
    >
      {props.leading && props.leading}
      <span className={props.className}>{props.children ?? props.label}</span>
      {props.trailing && props.trailing}
    </a>
  )
}

const componentMapping = new Map<LinkTypeType, MappingFunction<BaseLinkProps, JSX.Element>>([
  [LINK_TYPES.page, (props) => <PageLink {...props} />],
  [LINK_TYPES.web, (props) => <WebLink {...props} />],
  [LINK_TYPES.email, (props) => <EmailLink {...props} />],
]);

export function BaseLink(props: BaseLinkProps) {
  if (!props.type) {
    return null;
  }

  const componentControl = componentMapping.get(props.type);
  if (!componentControl) {
    return null;
  }

  return componentControl(props);
}

export default BaseLink;
