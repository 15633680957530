export function calculateColumnDivisionFactor(width: number) {
  const lg = 1024;
  const md = 768;
  const lgAndUp = width >= lg;
  const mdAndUp = width >= md;

  if (lgAndUp) {
    return 4;
  } else if (mdAndUp) {
    return 3;
  } else {
    return 2;
  }
}
