'use client';
import { MediaInterface } from '@deardigital/shared/interfaces';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import VideoPoster from './video-poster';

const ReactPlayer = dynamic(() => import('react-player/file').then((ReactPlayer) => ReactPlayer), {
  ssr: false,
});

/* eslint-disable-next-line */
export interface PlayerProps extends MediaInterface {
  poster?: MediaInterface | null;
}

export function Player(props: PlayerProps) {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const [playing, setPlaying] = useState(false);

  // Update playing state based on inView
  useEffect(() => {
    setPlaying(inView);
  }, [inView]);

  return (
    <div ref={ref} className="relative h-full w-full">
      <VideoPoster poster={props.poster} isPlaying={playing} loadingState={'eager'} />
      {inView && (
        <ReactPlayer
          url={props.url}
          width="100%"
          height="100%"
          playing={playing}
          loop={true}
          playsinline={true}
          muted={true}
          className="flex min-h-full w-full items-center"
        />
      )}
    </div>
  );
}

export default Player;
