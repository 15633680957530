import { Values } from './klaviyo-form';

export async function formSubmit(
  values: Values,
  klaviyoFormId: string | undefined,
  onSuccess: () => void,
) {
  const postData = {
    values,
    klaviyoFormId,
  }

  try {
    const url = '/api/whitepaper-request';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    onSuccess();
  } catch (error) {
    throw new Error(`Something went wrong ${error}`);
  }
}
