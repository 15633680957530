'use client';

import { ContentInfoCardCollapseInterface } from '@deardigital/shared/interfaces';
import { storyblokEditable } from '@storyblok/react';
import SectionBadge from '../../badge/section-badge/section-badge';
import LinkRenderer from '../../link/link-renderer.component';
import Section from '../../section/section';
import { Collapse } from './collapse';
import isEmpty from 'lodash.isempty';

export interface ContentInfoCardCollapseProps extends ContentInfoCardCollapseInterface {}

export function ContentInfoCardCollapse(props: ContentInfoCardCollapseProps) {
  if (!props.collapseItems) {
    return null;
  }

  return (
    <Section {...props.section}>
      <div {...(props._editable ? { ...storyblokEditable(props as any) } : {})}>
        <div className="container mx-auto px-5">
          {!isEmpty(props.section?.badge) && props.section?.badge && (
            <div className={`${props.section?.spacing || ''} !pb-4`}>
              {props.section.badge.map((item, key) => item && <SectionBadge key={key} {...item} {...props.section} />)}
            </div>
          )}
          <div className="lg:flex lg:gap-16">
            <div className="lg:w-1/2 mb-8 lg:mb-8">
              {/* @TODO: Change to rich text = more flexible */}
              <h2 className={`${props.section.theme.color.heading} font-medium font-sans text-3xl md:text-5xl mb-6`}>
                {props.title}
              </h2>
              {/* @TODO: add multiple links */}
              {props.links && <LinkRenderer {...props.links[0]} section={props.section} />}
            </div>
            <Collapse items={props.collapseItems} section={props.section} />
          </div>
        </div>
      </div>
    </Section>
  );
}
