/* eslint-disable-next-line */
export interface BadgeProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  spacing?: string | null;
}

export function BaseBadge(props: BadgeProps) {
  return (
    <div className={`px-5 inline-block rounded-full font-sans py-2 text-sm w-auto ${props.className}`}>
      {props.children}
    </div>
  );
}

export default BaseBadge;
