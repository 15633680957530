import { BREAKPOINTS } from '@deardigital/shared/constants';
import { ImageScrSetInterface } from '@deardigital/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function imageSizesGenerator(imageSrcSetConfig: ImageScrSetInterface, container?: boolean): string | null {
  if (!imageSrcSetConfig) return null;
  const sizes = Object.entries(imageSrcSetConfig || {}).reduce((acc, [breakpoint, width]) => {
    if (!width) return acc;

    const breakpointWidth = BREAKPOINTS[breakpoint as keyof typeof BREAKPOINTS];
    const vwWidth = calculateWidth(breakpointWidth, width, container);

    if (breakpoint === 'xs') {
      acc.push(vwWidth);
      return acc;
    }

    const entry = `(min-width: ${breakpointWidth}px) ${vwWidth}`;
    acc.push(entry);
    return acc;
  }, [] as string[]);

  if (isEmpty(sizes)) return null;

  return sizes.reverse().join(', ');
}

function calculateWidth(breakpointWidth: number, width: number, container = false): string {
  const AMOUNT_OF_GUTTERS = Math.max(0, width - 1);
  const GUTTER_WIDTH = 0 * AMOUNT_OF_GUTTERS; // needs to be replaced with the actual gutter width from respective component

  if (container && breakpointWidth >= BREAKPOINTS.sm) {
    return `${Math.round((breakpointWidth - 40 - GUTTER_WIDTH) / width)}px`;
  }

  if (!container && breakpointWidth >= BREAKPOINTS.sm) {
    return `${Math.round((breakpointWidth - GUTTER_WIDTH) / width)}px`;
  }

  if (container && breakpointWidth < BREAKPOINTS.sm) {
    return `calc(${Math.round(100 / width)}vw - 40px - ${GUTTER_WIDTH}px)`;
  }

  return `${Math.round(100 / width)}vw`;
}
