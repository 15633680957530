import { ColorShade, LINK_STYLES, LinkStyleType } from '@deardigital/shared/constants';
import { LinkInterface, MappingFunction, SectionInterface } from '@deardigital/shared/interfaces';
import BlueLink from './blue';
import BlueLight from './blue-badge';
import { IndigoFilterLink } from './indigo-filter';
import IndigoFilterOutlineLink from './indigo-filter-outline';
import IndigoOutlineLink from './indigo-outline';
import PrimaryLink from './primary';
import PrimaryOutlineLink from './primary-outline';
import TextIndigoLink from './text-indigo';
import TextWhiteLink from './text-white';
import WhiteOutlineLink from './white-outline';
import { SecondaryLink } from './secondary';
import SecondaryOutlineLink from './secondary-outline';
import TextLink from './text-link';

/* eslint-disable-next-line */
export interface LinkRendererProps extends LinkInterface {
  children?: React.ReactNode;
  section?: SectionInterface;
  theme?: ColorShade;
  size?: string;
}

const componentMapping = new Map<
  LinkStyleType,
  MappingFunction<LinkRendererProps, JSX.Element>
>([
  [LINK_STYLES.textIndigo, (props) => <TextIndigoLink {...props} />],
  [LINK_STYLES.textWhite, (props) => <TextWhiteLink {...props} />],
  [LINK_STYLES.blue, (props) => <BlueLink {...props} />],
  [LINK_STYLES.indigoOutline, (props) => <IndigoOutlineLink {...props} />],
  [LINK_STYLES.whiteOutline, (props) => <WhiteOutlineLink {...props} />],
  [LINK_STYLES.indigoFilter, (props) => <IndigoFilterLink {...props} />],
  [LINK_STYLES.indigoFilterOutline, (props) => <IndigoFilterOutlineLink {...props} />],
  [LINK_STYLES.blueLight, (props) => <BlueLight {...props} />],
  [LINK_STYLES.primary, (props) => <PrimaryLink {...props} />],
  [LINK_STYLES.primaryOutline, (props) => <PrimaryOutlineLink {...props} />],
  [LINK_STYLES.secondary, (props) => <SecondaryLink {...props} />],
  [LINK_STYLES.secondaryOutline, (props) => <SecondaryOutlineLink {...props} />],
  [LINK_STYLES.textLink, (props) => <TextLink {...props} />],
]);

export function LinkRenderer(props: LinkRendererProps) {
  if (!props.type) {
    return null;
  }

  const componentControl = componentMapping.get(props.linkStyle);
  if (!componentControl) {
    return null;
  }

  return componentControl(props);
}

export default LinkRenderer;
