'use client';

import { ContentServicesOverviewInterface, ServiceCardInterface, TechnologyCardInterface } from '@deardigital/shared/interfaces';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRef, useState } from 'react';
import IndigoBadge from '../../badge/indigo-badge';
import LightBlueBadge from '../../badge/light-blue-badge';
import Section from '../../section/section';

/* eslint-disable-next-line */
export interface ContentServicesOverviewProps extends ContentServicesOverviewInterface { }

export function ContentServicesOverview(props: ContentServicesOverviewProps) {
  const [activeChallenge, setactiveChallenge] = useState(props.challenges[0])
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"]
  });

  const BG_GRADIENT = [
    'bg-gradient-0',
    'bg-gradient-1',
    'bg-gradient-2'
  ]

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const closestIndex = Math.round(latest * (props.challenges.length - 1));
    const closestElement = props.challenges[closestIndex];
    activeChallenge !== closestElement && setactiveChallenge(closestElement);
  })

  function badgeRenderer(item: ServiceCardInterface | TechnologyCardInterface, key: number) {
    if (item.challenges?.some(challenge => challenge.slug === activeChallenge.slug)) {
      return (
        <IndigoBadge key={key} className='text-sm whitespace-nowrap'>{item.title}</IndigoBadge>
      )
    }

    return (
      <LightBlueBadge key={key} className='text-sm whitespace-nowrap'>{item.title}</LightBlueBadge>
    )
  };


  return (
    <Section {...props.section}>
      <div ref={ref} className="flex flex-col gap-y-4">
        {props.services.map((items, key) => (
          <div key={key} className={`flex gap-x-4 justify-center overflow-hidden relative after:absolute after:inset-0 after:z-1 after:w-full after:h-full ${BG_GRADIENT[key]}`}>
            {items.map((service, key) => badgeRenderer(service, key))}
          </div>
        ))}

        <div className='relative container mx-auto px-5'>
          <div className="flex overflow-x-auto whitespace-no-wrap invisible">
            {props.challenges?.map((challenge, key) => (
              <div key={key} className={`flex-shrink-0 w-full p-4 bg-gray-200 ${props.section.theme.editor}`} dangerouslySetInnerHTML={{ __html: challenge.text }} />
            ))}
          </div>
          <div className='absolute inset-0 px-5'>
            <div className='flex items-center h-full text-center'>
              {activeChallenge.text && <motion.div className={`${props.section.theme.editor}`} dangerouslySetInnerHTML={{ __html: activeChallenge.text }} />}
            </div>
          </div>
        </div>

        {props.technologies.map((items, key) => (
          <div key={key} className={`flex gap-x-4 justify-center overflow-hidden relative after:absolute after:inset-0 after:z-1 after:w-full after:h-full ${BG_GRADIENT[2 - key]}`}>
            {items.map((technology, key) => badgeRenderer(technology, key))}
          </div>
        ))}
      </div>
    </Section>
  );
}

export default ContentServicesOverview;
