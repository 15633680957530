'use client';

import { VideoPartialInterface } from '@deardigital/shared/interfaces';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';
import VideoPoster from './video-poster';

// Dynamically import ReactPlayer for file playback
const ReactPlayerFile = dynamic(() => import('react-player/file'), { ssr: false });
const ReactPlayerLazy = dynamic(() => import('react-player/lazy'), { ssr: false });

/* eslint-disable-next-line */
export interface VideoPlayerProps {
  video: VideoPartialInterface;
  overlay?: boolean;
  posterLoadingState?: 'lazy' | 'eager';
}

export function VideoPlayer({ video, posterLoadingState }: VideoPlayerProps) {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const [playing, setPlaying] = useState(false);

  // Update playing state based on inView
  useEffect(() => {
    setPlaying(inView);
  }, [inView]);

  if (!video) return null;

  const hasYoutubeUrl = video.youtube;
  const hasExternalUrl = video.url;
  let videoUrl = video.asset?.url;

  if (hasYoutubeUrl && video.youtube) {
    videoUrl = video.youtube;
  } else if (hasExternalUrl) {
    videoUrl = hasExternalUrl;
  }

  const ReactPlayerComponent = hasYoutubeUrl || hasExternalUrl ? ReactPlayerLazy : ReactPlayerFile;

  return (
    <div ref={ref} className="relative h-full w-full">
      <VideoPoster poster={video.poster} isPlaying={playing} loadingState={posterLoadingState} />
      {inView && (
        <ReactPlayerComponent
          url={videoUrl}
          width="100%"
          height="100%"
          playing={playing}
          loop={true}
          playsinline={true}
          muted={true}
          className="flex min-h-full w-full items-center"
        />
      )}
    </div>
  );
}

export default VideoPlayer;
