import { ICONS } from '@deardigital/shared/constants';
import IconRenderer from '../icons/icon-renderer.component';
import { SectionInterface } from '@deardigital/shared/interfaces';

export interface ButtonRightProps {
  section: SectionInterface;
}

export function ButtonRight({ section }: ButtonRightProps) {
  const buttonClass = section?.theme.button?.primary;

  return (
    <div className="swiper-button-next">
      <IconRenderer
        className={`
        flex h-12 w-12 items-center border justify-between rounded-full ease-in-out duration-300 cursor-pointer p-4
        ${buttonClass?.outlineDefault}
        ${buttonClass?.textOutline}
        ${buttonClass?.outlineHoverBackground}
        ${buttonClass?.outlineHoverText}
        ${buttonClass?.bgHover}
        ${buttonClass?.bgActive}
    `}
        icon={ICONS.arrowRight}
      />
    </div>
  );
}

export default ButtonRight;
