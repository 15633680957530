'use client';

import { SectionInterface, WhitepaperCardInterface } from '@deardigital/shared/interfaces';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import ImageRenderer from '../image-renderer/image-renderer';
import { FormFields } from './form-fields';
import { formSubmit } from './form-submit';
import { validationSchema } from './validation';

export interface Values {
  name: string;
  email: string;
  company: string;
}

export interface KlaviyoFormProps {
  section: SectionInterface;
  klaviyoFormId: string;
  thumbnail: WhitepaperCardInterface['thumbnail'];
  thankYouMessage?: string;
  text?: string | null;
}

export function KlaviyoForm({ klaviyoFormId, thumbnail, thankYouMessage, section, text }: KlaviyoFormProps) {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const formConfig = {
    initialValues: {
      name: '',
      email: '',
      company: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values, { resetForm }: FormikHelpers<Values>) => {
      function onSuccess() {
        setShowMessage(true);
        resetForm();
      }
      formSubmit(values, klaviyoFormId, onSuccess);
    },
  };

  if (!klaviyoFormId || !thumbnail) {
    return null;
  }

  return (
    <div className="grid gap-6 lg:grid-cols-12 klaviyo">
      <div className="lg:col-span-8 py-12 md:py-20">
        {showMessage && thankYouMessage && (
          <div className={section.theme.editor} dangerouslySetInnerHTML={{ __html: thankYouMessage }} />
        )}
        <div className="lg:w-4/5">
          {!showMessage && (
            <>
              {text && <div className={`mb-8 ${section.theme.editor}`} dangerouslySetInnerHTML={{ __html: text }} />}
              <FormFields formConfig={formConfig} section={section} />
            </>
          )}
        </div>
      </div>
      <div className="lg:col-end-13 lg:col-span-4">
        {thumbnail && <ImageRenderer loadingState={'lazy'} className="h-full w-full rounded-3xl object-cover" image={thumbnail} />}
      </div>
    </div>
  );
}

export default KlaviyoForm;
